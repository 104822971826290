@font-face {
  font-family: Knockout30;
  font-weight: bold;
  src: url("/assets/fonts/Knockout-30.otf") format("opentype");
}
.hamburger {
  background: white;
  margin-left: 16px;
  width: 38px;
  height: 33px;
  padding: 0px 1px 0px 1px;
}
.hamburger .line {
  width: 30px;
  height: 3px;
  background-color: #f2c42d;
  display: block;
  margin: 6px auto;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  z-index: 6;
  margin-left: 4px;
}
.hamburger:hover {
  cursor: pointer;

}
#hamburger {
  z-index: 9999;
  position: relative;
}
#hamburger.is-active .line:nth-child(2) {
  opacity: 0;
}
#hamburger.is-active .line:nth-child(1) {
  -webkit-transform: translateY(12px) rotate(45deg);
  transform: translateY(12px) rotate(45deg);
}
#hamburger.is-active .line:nth-child(3) {
  -webkit-transform: translateY(-6px) rotate(-45deg);
  transform: translateY(-6px) rotate(-45deg);
}
/* .mobile-menu--green {
  background-color: #c7202f;
}
.mobile-menu--green .mobile-menu-dropdown {
  background-color: #c7202f;
}
.mobile-menu--red {
  background-color: #c7202f;
}
.mobile-menu--red .mobile-menu-dropdown {
  background-color: #c7202f;
} 
.mobile-menu--blue {
  background-color: blue;
}
.mobile-menu--blue .mobile-menu-dropdown {
  background-color: blue;
}*/
.c-mobile-menu {
  position: fixed;
  top: 0;
  z-index: 10;
  height: 3rem;
  width: 100%;
  color: #fff;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  transition: background 0.1s ease;
}
.c-mobile-menu .mobile-menu__hamburger {
  float: left;
  margin: 1rem;
  cursor: pointer;
}
.c-mobile-menu .mobile-menu__logo {
  float: right;
  margin: 1rem;
  cursor: pointer;
}
.c-mobile-menu .mobile-menu-dropdown {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  opacity: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #ffff;
  width: 50vw;
  -webkit-box-shadow: 5px 0px 8px -5px rgba(0,0,0,0.75);
  -moz-box-shadow: 5px 0px 8px -5px rgba(0,0,0,0.75);
  box-shadow: 5px 0px 8px -5px rgba(0,0,0,0.75);
}
.c-mobile-menu .mobile-menu-dropdown .c-navigation {
  display: block;
  position: absolute;
  text-align: left;
  margin: 0;
  padding: 0;
  left: 5rem;
  top: 3rem;
}
.c-mobile-menu .mobile-menu-dropdown .c-navigation li {
  color: #013D57;
  text-decoration: none;
  font-size: 36px;
  line-height: 68px;
  list-style: none;
  cursor: pointer;
  font-family: "Knockout 50 A", "Knockout 50 B";
  font-style: normal;
  font-weight: 400 !important;
  letter-spacing: 4px;

}
.c-mobile-menu .mobile-menu-dropdown .c-navigation li:active {
  color: #fff;
}
.c-mobile-menu .mobile-menu-dropdown .c-navigation li .side-arrow-container {
  display: none;
}
.c-mobile-menu .mobile-menu-dropdown .c-navigation li.active {
  font-weight: 700;
  color:  #00A6CC;
  border-bottom: 3px solid #00A6CC;
}
.c-mobile-menu .mobile-menu-dropdown .c-navigation li.active .side-arrow-container {
  display: none;
}
.c-mobile-menu .light-box-bg {
  width: 100%;
  background: rgba(0,0,0,0.5);
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}
@media screen and (min-width: 1440px) {
  .c-mobile-menu {
    display: none;
 }
}
@media (min-width: 1440px) and (max-width: 1920px) {
  .c-mobile-menu {
    display: none;
 }
}
@media (min-width: 1025px) and (max-width: 1440px) {
  .c-mobile-menu {
    display: none;
 } 
}
@media (max-width: 460px) { 
  .c-mobile-menu .mobile-menu-dropdown {
    width: 80vw !important;
  }
  .c-mobile-menu .mobile-menu-dropdown .c-navigation li {
    font-size: 27px;
  }
}
@media only screen and (min-width: 460px) and (max-width: 640px) {
  .c-mobile-menu .mobile-menu-dropdown {
    width: 60vw !important;
  }
  .c-mobile-menu .mobile-menu-dropdown .c-navigation li {
    font-size: 30px;
  }
}
