#communities-section,
#schools-section,
#teammates-section {
    margin-top: 50px;
}

#schools-section .modal-video {
    display: block !important;
}

#schools-section .modal-video-body,
#teammates-section .modal-video-body,
#communities-section .modal-video-body {
    max-width: 100%;
}

#schools-section .modal-video-close,
#teammates-section .modal-video-close,
#communities-section .modal-video-close {
    top: -15% !important;
    width: 7% !important;
    height: 20% !important;
}

#schools-section .video-gallery-container,
#teammates-section .video-gallery-container {
    flex-direction: row;
}

#communities-section .video-gallery-container {
    flex-direction: row-reverse;
}

.video-backpack {
    mix-blend-mode: multiply;
    position: absolute;
    padding-bottom: 67%;
    right: 50px;
    z-index: 1;
}

.video-plane {
  position: absolute;
  margin-left: 65%;
  margin-top: 61px;
  mix-blend-mode: normal;
  z-index: 9999;
}

.video-section-arrow-container {
    margin-left: 10%;
}

.video-gallery-container {
    display: flex;
    align-items: center;
    max-width: 100%;
    font-family: "Gotham A", "Gotham B";
    font-style: normal;
    font-weight: 500;
}

.schools-photo-container {
    background-image: url('https://movement-assets.imgix.net/media/movementfoundation/red-box-923-4.jpg');
    padding-top: 50px;
}

.photo-container {
    width: 50%;
    background-size: cover;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.photo-container .photo-bg {
    width: 100%;
    height: auto;
    z-index: 9998;
}

.photo-container .photo-bg-tablet {
    display: none;
}

.photo-container .plane {
    position: absolute;
    margin-top: -30%;
    right: 15%;
    z-index: 10;
}

.photo-container .video-btn {
    transition: all .2s ease-in-out;
    cursor: pointer;
    z-index: 9999 !important;
}

.photo-container .video-btn:hover {
    transform: scale(1.1);
}

#schools-section .photo-container .video-btn {
    position: absolute;
    padding: 0 0 10% 0;
}

#communities-section .photo-container .video-btn {
    position: absolute;
    padding: 0 6% 2% 0;
}

#teammates-section .photo-container .video-btn {
    position: absolute;
    padding: 13% 0 0 3%;
}


#schools-section .text-container {
    padding-left: 10%;
}

#schools-section .text-container p {
    padding-right: 15%;
}

#communities-section .text-container {
    padding-right: 10%;
}

#communities-section .text-container p,
#communities-section .text-container h1,
#communities-section .text-container .link-container {
    padding-left: 15%;
}

#teammates-section .text-container {
    padding-left: 10%;
}

#teammates-section .text-container p {
    padding-right: 15%;
}

.text-container {
    max-width: 40%;
}

.text-container h1 {
    font-size: 26px;
    color: #00A6CC;
}

.text-container p {
    margin-top: 30px;
    font-size: 18px;
    line-height: 28px;
    color: #8B8B8B;
}

.link-container a {
    letter-spacing: 1px;
    display: inline-block;
    margin-top: 50px;
    position: relative;
    font-size: 18px;
    line-height: 1;
    font-weight: 700;
    color: #4BD0E6;
    text-transform: uppercase;
    text-decoration: none;
    transition: color 0.3s ease;
}

.link-container a:after {
    content: url('https://movement-assets.imgix.net/media/movementfoundation/video-link-arrow.svg');
    position: absolute;
    right: -75px;
    transition: right 0.3s ease;
}

.link-container a:hover {
    color: #4BD0E6;
}

.link-container a:hover:after {
    right: -80px;
}

.iLciyF{
    height: 180px !important;
    width: 150px !important;
  }


  #schools-section .photo-container .photo-bg {
    border-bottom: 3px solid #ffffff;
}

@media only screen and (min-width: 1200px) {
  #schools-section{
    margin-top: 200px;
  }
  
}

@media only screen and (max-width: 1200px){
  #teammates-section .video-plane {
    margin-left: 70%;
  }
}

@media only screen and (max-width: 1024px) {
    #communities-section .text-container {
        margin-top: -10%;
        z-index: 3;
    }

    #teammates-section .text-container {
        margin-top: -6%;
        z-index: 3;
    }

    .photo-container .video-btn {
        z-index: 3 !important;
    }

    #schools-section .video-gallery-container,
    #teammates-section .video-gallery-container {
        flex-direction: column-reverse;
    }

    #communities-section .video-gallery-container {
        flex-direction: column-reverse;
    }

    .video-section-arrow-container {
        display: flex;
        justify-content: center;
        margin-left: 0;
        margin-bottom: 120px;
    }

    /* .schools-photo-container {
        background-image: none;
        padding-top: 0px;
    } */

    .video-gallery-container {
        display: flex;
        margin-left: 0%;
        margin-right: 0%;
        max-width: 100%;
    }

    .photo-container {
        width: 100%;
    }

    #schools-section .photo-container .video-btn {
        position: absolute;
        padding: 11% 8% 0 0;
        z-index: 10;
    }

    #communities-section .photo-container .video-btn {
        position: absolute;
        padding: 5% 0 0 10%;
        z-index: 10;
    }

    #teammates-section .photo-container .video-btn {
        position: absolute;
        padding: 18% 5% 0 0%;
        z-index: 10;
    }

    #schools-section .photo-container .photo-bg {
        display: none;
    }

    #schools-section .photo-container .photo-bg-tablet {
        display: block;
    }

    #schools-section .photo-container .photo-bg-tablet {
        width: 100%;
        height: auto;
        z-index: 2;
        margin-right: 10%;
        margin-bottom: -10%;
        margin-top: 3%;
        width: 90%;
    }

    #schools-section .photo-container {
        width: 80%;
    }

    #schools-section .schools-photo-container {
        background-image: url('https://movement-assets.imgix.net/media/movementfoundation/red-box-923-4-mobile.jpg');
        padding-top: 50px;
    }

    .photo-container .photo-bg {
        display: none;
    }

    .photo-container .photo-bg-tablet {
        width: 100%;
        height: auto;
        display: block;
    }

    #schools-section .text-container p {
        padding-right: 0%;
    }

    #teammates-section .video-plane{
      margin-left: 45%;
      margin-top: 110px;
      mix-blend-mode: multiply;
    }

    .sc-bqGHjH.coROHW {
      height: 13.9vw;
      width: 33.1vw;
    }

    #schools-section .video-backpack{
      right: 20%;
      padding-bottom: 75%;
    }

    #communities-section .text-container p,
    #communities-section .text-container h1,
    #communities-section .text-container .link-container {
        padding-left: 0%;
    }

    #teammates-section .text-container p {
        padding-right: 0%;
    }

    #teammates-section .text-container {
      padding-bottom: 100px;
    }

    #schools-section .text-container {
        margin-top: 15%;
    }

    .text-container {
        max-width: 100%;
        padding: 0 10%;
    }

    .text-container p {
        margin-top: 30px;
        font-size: 16px;
        line-height: 26px;
    }
}

@media only screen and (max-width: 640px) {

    #schools-section .photo-container .video-btn {
        position: absolute;
        padding: 10% 8% 0 0;
    }

    .text-container h1 {
        font-size: 23px;
    }

    .text-container p {
        margin-top: 30px;
        font-size: 14px;
        line-height: 20px;
    }

    .link-container a {
        margin-top: 30px;
    }

    .photo-container .video-btn img {
        width: 120px;
    }
    .iLciyF{
        height: 130px !important;
        width: 115px !important;
    }
    #schools-section .video-backpack{
      padding-bottom: 78%;
    }
}

@media only screen and (max-width: 425px) {
    .link-container a {
        font-size: 14px;
    }

    .photo-container .video-btn img {
        width: 100px;
        height: auto;
    }
    .iLciyF{
        height: 80px !important;
        width: 70px !important;
    }
}

/* Animated Airplane */
@media only screen and (max-width: 750px) {
  #teammates-section .video-plane{
    margin-left: 55%;
    margin-top: 85px;
    mix-blend-mode: multiply;
  }
}
@media only screen and (max-width: 600px) {
  #teammates-section .video-plane{
    margin-left: 60%;
    margin-top: 70px;
    mix-blend-mode: multiply;
  }
}
@media only screen and (max-width: 600px) {
  #teammates-section .video-plane{
    margin-left: 70%;
    margin-top: 60px;
    mix-blend-mode: multiply;
  }
}
@media only screen and (max-width: 400px) {
  #teammates-section .video-plane{
    margin-left: 82%;
    margin-top: 38px;
    mix-blend-mode: multiply;
  }
}



/* Schools Image */

@media only screen and (max-width: 700px) {
    #schools-section .photo-container .photo-bg-tablet {
        margin-top: 1%;
    }
}

@media only screen and (max-width: 700px) {
    #schools-section .photo-container .photo-bg-tablet {
        margin-top: -1%;
    }
}


@media only screen and (max-width: 500px) {
    #schools-section .photo-container .photo-bg-tablet {
        margin-top: -3%;
    }
}


@media only screen and (max-width: 450px) {
    #schools-section .photo-container .photo-bg-tablet {
        margin-top: -7%;
    }
}

@media only screen and (max-width: 400px) {
    #schools-section .photo-container .photo-bg-tablet {
        margin-top: -6%;
    }
}


@media only screen and (max-width: 350px) {
    #schools-section .photo-container .photo-bg-tablet {
        margin-top: -8%;
    }
}

@media only screen and (max-width: 300px) {
    #schools-section .photo-container .photo-bg-tablet {
        margin-top: -10%;
    }
}
