
@import './mobileMenu.css';
@import './desktopMenu.css';


header.c-header + div.c-subHeader {
  background-attachment: initial !important;
}
.title {
  font-weight: 600;
}

@media (min-width: 1025px) and (max-width: 1440px) {
  .content-section {
    padding: 65px 100px;
 }
  .content-padding {
    padding: 65px 100px;
 }
}
@media (min-width: 640px) and (max-width: 1024px) {
  .content-section {
    padding: 40px 65px;
 }
  .content-padding {
    padding: 40px 65px;
 }
  .c-desktop-menu {
    display: none;
 }
}
@media screen and (max-width: 640px) {
  .content-section {
    padding: 50px 25px;
 }
  .content-padding {
    padding: 50px 25px;
 }
  .c-desktop-menu {
    display: none;
 }
}
.sm-button .l-wistia-video__play-button {
  width: 5% !important;
}
@media (max-width: 414px) {
  .sm-button .l-wistia-video__play-button {
    width: 12% !important;
 }
}
