.video-wrapper video {
    object-fit: fill;
}
.video-wrapper{
  position: absolute;
  height: 100%;
  width: 100%;
}
.stop{
    background-image:url(https://movement-assets.imgix.net/media/movementfoundation/stop.svg);
    background-repeat:no-repeat;
    width: 4%;
    height: 15%;
    position: absolute;
    left: 0%;
    right: -91%;
    top: -68%;
    bottom:0%;
    margin:auto;
    background-size:contain;
    background-position: center;
    z-index: 1001;
    cursor: pointer;
  }
  .pause {
    background-image:url(https://movement-assets.imgix.net/media/movementfoundation/pause.svg);
    background-repeat:no-repeat;
    width:15%;
    height:25%;
    position:absolute;
    left:0%;
    right:0%;
    top:0%;
    bottom:0%;
    margin:auto;
    background-size:contain;
    background-position: center;
    z-index: 1001;
    cursor: pointer;
    display:none !important;
  }
  .video_overlay {
    background-image: url(/assets/dummy.png);
    background-repeat: no-repeat;
    background-size: 100vw 100vh;
    position:absolute;
    float:left;
    width:100%;
    height: 100%;
    min-height:100%;
    z-index:1000;
  }
  .play{
    background-image:url(https://movement-assets.imgix.net/media/movementfoundation/play.svg);
    background-repeat:no-repeat;
    width:8%;
    position:absolute;
    left:0%;
    right:0%;
    top:0%;
    bottom:0%;
    margin:auto;
    background-size:contain;
    background-position: center;
    z-index: 1001;
    cursor: pointer;
  }
  .hide {
    display: none;
  }
  .show {
    display: block;
  }
