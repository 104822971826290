/*320*/
@media only screen and (max-width: 320px) {
    .header {
        height: 457px;
     /*   background-position: 125px -60px;
        background-size: 82% 56%;*/
    }
    .hope-title {
        width: 71%;
        letter-spacing: 1.9px;
        line-height: 3rem;
        margin: 1em auto;
    }
    .logo-txt {
        margin-top: 1rem;
        font-size: 18px !important;
    }
    .logo-img-header {
        object-fit: cover;
        width: 35px;
        height: 35px;
    }
    .wavy-lines {
        top: -3rem!important;
        right: -3rem!important;
        max-width: 80%;
        /* max-height: 80%; */
    }
}
/*320*/
/*640*/
@media only screen and (min-width: 321px) and (max-width: 640px) {
    .header {
        height: 474px;
    }    
    .logo {
        padding-top: 3rem !important;
    }
    .hope-title {
        max-width: 445px;
        letter-spacing: 1.9px;
        line-height: 1.5em;
        margin: -0.3em auto;
        padding: 3rem;
    }
    .logo-txt {
        margin-top: 1rem;
    }
    .logo-img-header {
        object-fit: cover;
        width: 35px;
        height: 35px;
    }
    .wavy-lines {
        top: -5rem !important;
        right: -5rem !important;
        max-width: 80% ;
        /* max-height: 80%; */
    }
}
/*640*/
/*1024*/
@media only screen and (min-width: 641px) and (max-width: 1024px) {
    .header {
        height: 504px;
    /*    background-position: 315px -100px;
        background-size: 75% 105%; */
    }
    .logo {
        padding-top: 4.2rem !important;
    }
    .hope-title {
        max-width: 525px;;
        letter-spacing: 4px;
        line-height: 4rem;
        margin: 4.5rem auto;
    }
    .logo-txt {
        margin-top: 1.725rem;
    }
    .logo-img-header {
        object-fit: cover;
        width: 59px;
        height: 59px;
    }
    .wavy-lines {
        top: -8rem !important;
        right: -8rem !important;
        max-width: 70%;
        /* max-height: 70%; */
    }
}
/*768*/
/*1440*/
@media only screen and (min-width: 1025px) and (max-width: 1440px) {
    .header {
        height: 517px;
     /*   background-position-x: 178%;
        background-position-y: 50%;*/
    }
    .hope-title {
        width: 50%;
        letter-spacing: 5px;
        line-height: 1.15em;
        margin: 1.5em auto;
    }
    .logo-txt {
        margin-top: 1.5em;
    }
    .logo-img-header {
        object-fit: cover;
        width: 59px;
        height: 59px;
    }
    .wavy-lines {
        top: -15rem !important;
        right: -15rem !important;
        max-width: 70%;
        /* max-height: 70%; */
    }
}
/*1440*/
/*1920*/
@media only screen and (min-width: 1441px) and (max-width: 1919px) {
    .header {
        height: 605px;
        /*background-position-x: 161%;
        background-position-y: 45%;
        background-size: 70%;*/
    }
    .hope-title {
        width: 53%;
        letter-spacing: 5px;
        line-height: 1.15em;
        margin: 1.5em auto;
    }
    .logo-txt {
        margin-top: 2.3rem;
    }
    .logo-img-header {
        object-fit: cover;
        width: 3.5%;
        height: 3.5%;
        margin-top: 66px !important;
    }
    .logo {
        padding-top: 0 !important;
    }
    .wavy-lines {
        top: -15rem ;
        right: -15rem;
        max-width: 70%;
        /* max-height: 70%; */
    }
}
@media only screen and (min-width:1920px) {
    .header {
        height: 605px;
        background-position-x: 161%;
        background-position-y: 45%;
        background-size: 70%;
    }
    .hope-title {
        width: 53%;
        letter-spacing: 5px;
        line-height: 1.15em;
        margin: 1.5em auto;
    }
    .logo-txt {
        margin-top: 2.3rem;
    }
    .logo-img-header {
        object-fit: cover;
        width: 3.5%;
        height: 3.5%;
        margin-top: 66px !important;
    }
    .logo {
        padding-top: 0 !important;
    }
    .wavy-lines {
        top: -12rem;
        right: -17rem;
        max-width: 100%;
        /* max-height: 100%; */
    }
}
/*1920*/
.header {
    text-transform: uppercase;
    text-align: center;
}
.wavy-lines {
    position: absolute;
    z-index: 10;
    transform: rotate(10deg);
}

.yellow-color {
    color: #deb325;
}
.logo {
    padding-top: 3rem;
}
.logo-txt {
    font-family: "Gotham A", "Gotham B";
    font-style: normal;
    font-weight: 600;
    font-size: 19px;
}
.hope-title {
    font-family: "Knockout 29 A", "Knockout 29 B";
    font-style: normal;
    font-weight: 400;
    font-size: calc(35px + (65 - 35) * ((100vw - 320px) / (1920 - 320)));       
}
.heading {
    margin-top: 100px;
}



