.invest-container {
    background-color:#013D57;
    background-image: url('https://movement-assets.imgix.net/media/movementfoundation/buildings-928.jpg');
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: contain;
}

.invest-wrapper {
    padding: 160px 12% 170px 12%;
    color: #fff;
}

.invest-container h1 {
    font-family: "Knockout 50 A", "Knockout 50 B";
    font-style: normal;
    font-weight: 300;
    font-size: 40px;
    text-transform: uppercase;
    margin-bottom: 60px;
}

.invest-container p {
    font-family: "Gotham A", "Gotham B", Arial, sans-serif;
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
}

@media only screen and (max-width: 768px) {
    .invest-wrapper {
        padding: 160px 18% 160px 18%;
        color: #fff;
    }
    
    .invest-container h1 {
        font-size: 40px;
        text-transform: uppercase;
        margin-bottom: 60px;
    }
    
    .invest-container p {
        font-size: 16px;
        line-height: 26px;
    }
}

@media only screen and (max-width: 650px) {
    .invest-wrapper {
        padding: 80px 10% 100px 10%;
        color: #fff;
    }
    
    .invest-container h1 {
        font-size: 40px;
        text-transform: uppercase;
        margin-bottom: 60px;
    }
    
    .invest-container p {
        font-size: 16px;
        line-height: 26px;
    }
}
