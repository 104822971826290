.container {
  position: absolute;
  width: 100%;
 }
 .relative-container {
   position: relative;
   overflow: hidden;
 }
 .videogallery-plane-relative-container {
   position: relative;
   overflow-x: hidden;
 }