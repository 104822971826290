
.c-desktop-menu ul {
  color: #013d57;
  text-transform: uppercase;
}
.c-desktop-menu .c-navigation {
  font-family: "Knockout 50 A", "Knockout 50 B", Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  height: 255px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 9999;
  position: fixed;
  width: 104px;
  top: 40%;
  list-style-type: none;
  font-size: 15px;
  background-color: #ffff;
  display: flex;
  justify-content: center;
  padding-left: 20px;

}
.c-desktop-menu .c-navigation li {
  display: flex;
  list-style: none;
  cursor: pointer;
  
  font-size: 12px;
  letter-spacing: 0.46px;
  transition: color 0.2s ease-in;
  margin-left: 10px;
  padding: 10px 0;
  font-family: "Knockout 50 A", "Knockout 50 B";
font-style: normal;
font-weight: 400;
}
.c-desktop-menu .c-navigation li .side-arrow-container > * {
  fill: white;
  transition: all 0.2s ease-in;
  margin-left: 5px;
}
.c-desktop-menu .c-navigation li.active {
  color: #00a6cc;
}
.c-desktop-menu .c-navigation li.inactive .side-arrow-container {
  display: none;
}
.c-desktop-menu .c-navigation li.inactive:hover .side-arrow-container {
  display: block;
}
.c-desktop-menu .c-navigation .bullet-container {
  border-left: 1px solid #ebebeb;
  margin-left: 10px;
}
.c-desktop-menu .c-navigation .side-arrow-container {
  height: 15px;
  left: 22px;
  position: absolute;
}
.desktop-menu--black ul {
  color: black;
}
.desktop-menu--black .c-navigation li .side-arrow-container > * {
  fill: black;
  transition: all 0.2s ease-in;
  margin-left: 5px;
}

@media only screen and (min-width: 1025px) and (max-width: 1225px) {
  
  .c-desktop-menu .c-navigation li {
    font-size: 12px;
  }    
.c-desktop-menu .c-navigation {
  margin-left: -10px;
  width:9%;
}
}
