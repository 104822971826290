@media only screen and (max-width: 320px) {
    .circle-down, .video-wrapper {
        display: none;
    }
    /* .children-bg {
        background-size: contain !important;
        height: 1130px;
    } */
    .why-we-txt {
        margin: 0 auto;
        padding: 2rem;
        box-shadow: 10px 10px 26px -16px rgba(0,0,0,0.75);
        width: 65%;
        margin-top: 10rem;
        margin-bottom: 10%;
       
    }
    .why-we-txt h2 {
        font-size: 35px !important;
        letter-spacing: 4px;
        padding: 0 0 1rem;
    }
    .why-we-txt p {
       
        letter-spacing: 1px;
        line-height: 1.5rem;
    }
    .p-txt-1 {
        font-size: 15px;
    }
    .p-txt-2 {
        font-size: 13px;
        margin-top: 2rem;
    }
    .play-btn-overlay {
        /* top: 10% !important; */
        z-index: 9 !important;
    }
    .play-light-btn {
        width: 50px;
        height: 50px;
        display: block;
        margin: 1.7rem -10rem 3.5rem 7rem;
    }
    .video-play-button {
        top: 22% !important; 
        left:50% !important;
    }
    .video-play-button:before {
        width: 52px !important;
        height: 52px !important;
        
    }
    .video-play-button:after {
        width: 45px !important;
        height: 45px !important; 
    }
    .video-play-button span {
        display: block;
        position: relative;
        z-index: 3;
        width: 0;
        height: 0;
        border-left: 15px solid #00A6CC !important;
        border-top: 8px solid transparent !important;
        border-bottom: 8px solid transparent !important;
        margin: 13px 4px 9px 6px !important;
    }
    /* .modal-video-movie-wrap {
        top: 2.5rem !important;
    } */
    .modal-video-close {
        top: -20% !important;
        width: 10% !important;
        height: 17% !important;
    } 
}
@media only screen 
and (min-device-width: 320px)
and (orientation : landscape) {
    #youtube-player .modal-video-movie-wrap {
        padding-bottom: 25% !important;
        width: 45% !important;
    }
}
/* @media only screen and (min-device-width: 321px)
        and (max-device-width: 640px)
        and (orientation : landscape) {
    #youtube-player .modal-video-movie-wrap {
        padding-bottom: 25% !important;
        width: 45% !important;
    }
} */
@media only screen and (min-width: 321px) and (max-width: 640px) {
    .circle-down, .video-wrapper {
        display: none;
    }
    /* .children-bg {
        background-size: contain !important;
        height: 1046px;
    } */
    .why-we-txt {
        margin: 0 auto;
        padding: 10%;
        box-shadow: 10px 10px 26px -16px rgba(0,0,0,0.75);
        width: 65%;
        margin-top: 10rem;
        margin-bottom: 10%;
    }
    .why-we-txt h2 {
        font-size: 35px !important;
        letter-spacing: 4px;
        padding: 0 0 1rem;
    }
    .why-we-txt p {
        
        letter-spacing: 1px;
        line-height: 1.5rem;
    }
    .p-txt-1 {font-size: 16px;}
    .p-txt-2 {
        font-size: 14px;
        margin-top: 2rem;
    }
    .play-btn-overlay {
        /* top: 20% !important; */
        z-index: 9 !important;
        
    }
    .play-light-btn {
        width: 96px;
        height: 159px;
        display: block;
        margin: 0 auto;
    }
    /* .bottom-border {
        top: 23%;
        bottom: unset!important;
        background-size: contain !important;
    } */
    .video-play-button {
        top: 23% !important;
        left:50% !important;
    }
    .video-play-button:before {
        width: 76px !important;
        height: 76px !important;
        
    }
    .video-play-button:after {
        width: 56px !important;
        height: 56px !important; 
    }
    .video-play-button span {        
        border-left: 16px solid #00A6CC !important;
        border-top: 8px solid transparent !important;
        border-bottom: 9px solid transparent !important;
        margin: 12px 13px 8px 6px;
    }
    /* .modal-video-movie-wrap {
        top: 3rem !important;
    } */
    .modal-video-close {
        top: -20% !important;
        width: 10% !important;
        height: 17% !important;
    }
    .videoWhyweexistContainer{
        padding-top: 10px;
    }
}
@media only screen and (min-width: 641px) and (max-width: 1024px) {
    .circle-down, .video-wrapper {
        display: none;
    }
    /* .children-bg {
        background-size: contain !important;
        height: 1289px;
    } */
    .why-we-txt {
        margin: 0 auto;
        padding: 5rem;
        box-shadow: 10px 10px 26px -16px rgba(0,0,0,0.75);
        width: 75%;
        margin-top: 10rem;
        margin-bottom: 10%;
    }
    .video-play-button:before {
        width: 136px !important;
        height: 136px !important;
        
    }
    .video-play-button:after {
        width: 104px !important;
        height: 104px !important; 
    }
        
    .why-we-txt h2 {
        font-size: 40px !important;
        letter-spacing: 5px;
        padding: 0 0 2rem;
        
    }
    .why-we-txt p {
        font-size: 18px;
        letter-spacing: 1px;
        line-height: 2rem;
    }
    .p-txt-2 {
        margin-top: 2rem;
    }
    .play-btn-overlay {
        /* top: 10% !important; */
        z-index: 9 !important;
    }
    .video-play-button {
        top: 26%!important;
        left: 52.5% !important;
        width:50px;
    }
    .play-light-btn {
        width: 104px;
        height: 104px;
        display: block;
        margin: 11rem 0rem 1rem 21rem;
    }
    .video-play-button span {
        border-left: 21px solid #00A6CC !important;
        border-top: 12px solid transparent !important;
        border-bottom: 12px solid transparent !important;
        margin: 12px 6px 6px 6px !important;
    }

    
    /* .bottom-border {
        top: 34%;
        bottom: unset!important;
        background-size: contain !important;
    } */
    .modal-video-close {
        top: -20% !important;
        width: 10% !important;
        height: 17% !important;
    }
}
@media only screen and (min-width: 1025px) and (max-width: 1440px) {
    .show-mobile-table {
        display: none !important;
    }
    .play-light-btn {
        display: none;
    }
    .video-play-button:before {
        width: 136px !important;
        height: 136px !important;
    }
    .video-play-button:after {
        width: 100px !important;
        height: 100px !important; 
    }
    .video-play-button {
        top: 42% !important;
        left: 28.5vw !important;
    }
    .video-play-button span{     
    border-left: 30px solid #00A6CC !important;
    border-top: 18px solid transparent !important;
    border-bottom: 18px solid transparent !important;
    }

    .play-btn-overlay {
        position: absolute !important;
        width: 42% !important;
        right: 3% !important;
        top: 54vw !important;
        left: 55%;
        z-index: 9;
        -webkit-transition: all 1s ease;
        -moz-transition: all 1s ease;
        -o-transition: all 1s ease;
        -ms-transition: all 1s ease;
        transition: all 1s ease;
    }
    .why-we-txt {
        padding: 4.5rem;
    }
    /* .why-we-txt h2 {
        padding: 1rem 0 3rem 0;
    } */
    
    .p-txt-1 {
        margin-top: 3rem;
    }
    .p-txt-2 {
        margin-top: 2rem;
    } 
    .why-we-txt p {
        font-size: 20px;
        letter-spacing: 1px;
        line-height: 2rem;
    }
    .why-we-txt h2 {
        letter-spacing: 5px;
        font-size: 40px;
    }
    /* .why-we-txt {
        left: 53%;
        height: 45rem;
        padding: 1.5rem 5rem;
    }
    .why-we-txt p {
        font-size: 18px;
        letter-spacing: 2px;
        line-height: 2rem;
    }
    .why-we-txt h2 {
        letter-spacing: 5px;
        font-size: 40px;
        padding: 3rem 0;
    }
    .p-txt-2 {
        margin-top: 2rem;
    } */
    /* .children-bg {
        height: 1299px;
    } */
    /* .bottom-border {
        background-size: cover;
        height: 458px !important;
    } */
    .stop{
        width:60px !important;
        
        right: -91%;
        top: -68%;
    } 
    .play{
        width: 136px;
        height: 136px;
    }
    .pause{   
        width: 179px;
        height: 179px;
    }

    .play-btn-overlay {
        position: absolute !important;
    }
    .circle-down {
        top: 0% !important;
    }
    .modal-video {
        display: none;
    }
}
@media only screen and (min-width: 1441px) and (max-width: 1919px) {
    .show-mobile-table {
        display: none !important;
    }
    .play-light-btn {
        display: none;
    }
    .circle-down{

        top:-4%
    }
    .top-frame{
        top: -3% !important;
    }
    .play-btn-overlay {
        position: absolute !important;
        width: 30% !important;
        right: 5% !important;
        top: 8% !important;
        left: 59%;
        -webkit-transition: all 1s ease;
        -moz-transition: all 1s ease;
        -o-transition: all 1s ease;
        -ms-transition: all 1s ease;
        transition: all 1s ease;
    }
    .why-we-txt {
        padding: 5rem;
        z-index: 2;
    }
    .why-we-txt p {
        font-size: 20px;
        letter-spacing: 1px;
        line-height: 2.2rem;
    }
    .why-we-txt h2 {
        letter-spacing: 5px;
        font-size: 45px;
    }
    .p-txt-1 {
       margin: 0;
       padding-top: 50px;
       padding-bottom: 50px;
    }
    .p-txt-2 {
        margin: 0;
    }
    /* .children-bg {
        height: 1627px;
    } */
    /* .bottom-border {
        background-size: cover;
        height: 512px !important;
    } */
    .video-play-button {
        top: 42% !important;
        left: 28vw !important;
    }
    .stop{
        width:75px !important; 
        right: -91%;
        top: -68%;
    } 
    .play{
        width: 154px;
        height: 154px;
    }
    .pause{   
        width: 179px;
        height: 179px;
    }
    .modal-video {
        display: none;
    }
}
@media only screen and (min-width: 1441px){
  .children-video-wrapper{
    height: 1350px;
  }
  .top-frame{
    height: 55%;
  }
  .bottom-frame{
    height: 35%;
  }
}
@media only screen and (min-width: 1920px) {
    /* .children-bg {
        height: 1627px;
    } */
    .show-mobile-table {
        display: none !important;
    }
    .play-light-btn {
        display: none;
    }
    .play-btn-overlay {
        position: absolute !important;
        width: 30% !important;
        top: 8% !important;
        left: 59%;
        -webkit-transition: all 1s ease;
        -moz-transition: all 1s ease;
        -o-transition: all 1s ease;
        -ms-transition: all 1s ease;
        transition: all 1s ease;
    }
    .why-we-txt {
        padding: 5rem;
        z-index: 2;
    }
    .why-we-txt p {
        font-size: 20px;
        letter-spacing: 1px;
        line-height: 2.2rem;
    }
    .why-we-txt h2 {
        letter-spacing: 5px;
        font-size: 45px;
        padding: 1rem 0 3.5rem 0;
    }
    .p-txt-1 {
        margin: 0;
        padding-bottom: 50px;
    }
    .p-txt-2 {
        margin: 0;
    }
    .video-play-button {
        top: 43% !important;
        left: 28vw !important;
    }
    .modal-video {
        display: none;
    }
    
.circle-down{

    top:-3%;
    
}
.top-frame{
    top:-3% !important
}

}

@media only screen and (min-width: 2000px) {
  .top-frame{
    height: 73% !important
  }
}

/* Common styles */
h2 {
    margin: 0!important;
}

/* .children-bg {
    background-image: url(/assets/why_we_exist/kids.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 20rem;;
    position: relative;
} */

.circle-down {
    position: absolute;
    z-index: 2;
    left: 49%;
    height: 109px;
    width: 45px;
}

.children-video-wrapper {
    position: relative;
}
.kids-bg {
    object-fit: cover;
    width: 100%;
    height: 99%
}

.top-frame {
    position: absolute;
    z-index: 1;
    object-fit: cover;
    width: 100%;
    top: -3px;
}

.bottom-frame {
    position: absolute;
    z-index: 1;
    object-fit: cover;
    width: 100%;
    bottom: -1px;
}


.bottom-border {
    background-image: url(https://movement-assets.imgix.net/media/movementfoundation/bottom-frame.png);
    background-repeat: no-repeat;
    height: 100%;
    position: absolute;
    bottom: 0;
    width: 100%;
}

.why-we-txt {   
    background-color: #fff;
    opacity: 1;
    position: relative;
}



.why-we-txt h2 {
    font-family: "Knockout 50 A", "Knockout 50 B";
    font-style: normal;
    font-weight: 400;
    text-transform: uppercase;
    color: #00A6cc;
}
.why-we-txt p {  
    color: #aaaaaa;
    font-family: "Gotham A", "Gotham B";
    font-style: normal;
    font-weight: 500;
}
.play-btn-overlay {
    position: relative;
    top: -15rem;
    width: 100%;
    overflow: hidden;
    
}
.play-btn {
    position: relative;
    top: 0;
    right: 20%;
    width: 250px;
    z-index: 6;
}
.reset-overlay {
    opacity: 1;
  }
  .overlay-slide {
    left: 100%;
  }
  
  .video-play-button {
    cursor: pointer;
    position: absolute;
    z-index: 10;
    top: 85vh;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    box-sizing: content-box;
    display: block;
    width: 32px;
    height: 44px;
    /* background: #fa183d; */
    border-radius: 50%;
    padding: 18px 20px 18px 28px;
  }
  
  .video-play-button:before {
    content: "";
    position: absolute;
    z-index: 0;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    display: block;
    width: 176px;
    height: 176px;
    background:#FFFFFF ;
    border-radius: 50%;
    animation: pulse-border 1500ms ease-out infinite;
  }
  
  .video-play-button:after {
    content: "";
    position: absolute;
    z-index: 1;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    display: block;
    width: 125px;
    height: 125px;
    background: #FFFFFF;
    border-radius: 50%;
    transition: all 200ms;
  }
  
  .video-play-button:hover:after {
    background-color: darken(#fa183d, 10%);
  }
  
  .video-play-button img {
    position: relative;
    z-index: 3;
    max-width: 100%;
    width: auto;
    height: auto;
  }
  
  .video-play-button span {
    display: block;
    position: relative;
    z-index: 3;
    width: 0;
    height: 0;
    border-left: 32px solid #00A6CC;
      border-top: 22px solid transparent;
      border-bottom: 22px solid transparent;
  }
  .hidePlayBtn {
      display: none;
  }
  
  @keyframes pulse-border {
    0% {
      transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
      opacity: 1;
    }
    100% {
      transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
      opacity: 0;
    }
  }
  
  
  .hide-light-box {
      display: none;
  }
  .modal-video-close {
    position: absolute;
    z-index: 2;
    right: 0px;
    display: inline-block;
    overflow: hidden;
    border: none;
    background-image:url(https://movement-assets.imgix.net/media/movementfoundation/stop.svg);
    background-repeat:no-repeat;
    background-size: contain;
    width: 10%;
    height: 20%;
    background-color: transparent;

}
/* .modal-video-inner {
    vertical-align: top !important;
} */
.modal-video-movie-wrap {
    width: 80% !important;
    margin: 0 auto;
    /* top: 7rem; */
    padding-bottom: 45% !important;
}

@media only screen and (max-width: 1024px) {
.video-play-button {
position: relative !important;
margin-top: -55%;
margin-bottom: 21%;
}
}
    
    @media only screen and (min-width:1025px) and (max-width:1260px){
        .why-we-txt  {    
            margin-top: 5rem;
            padding: 3.2rem 3rem 3.2rem 3rem;
        }
        .p-txt-1 {
            font-size: 18px !important;
        margin-top: 2rem !important;
        }
        .p-txt-2 {
            font-size: 16px !important;
            margin-top: 2rem;
        }
        .play-btn-overlay {
            width: 44% !important;
            }
    }

    @media only screen and (min-width: 1441px) and (max-width: 1800px) {
        .play-btn-overlay {
            width: 37% !important;
            margin-top: 5rem;
            }
        }


        @media only screen and (min-width: 768px) and (max-width: 1024px) {
            .why-we-txt {
                width: 63%;

            }
}
@media only screen and (min-width: 540px) and (max-width: 640px){

    .why-we-txt h2 {
        padding: 0 0 2rem;
}
}

