

/* How It Works CSS */
.howitworks-component {
  height: 2200px;
  width: 75%;
  margin: 0 auto;
}
.coin-section-content-container { 
  height: 1000px;
}
.coin-section-top {
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  justify-content: space-between;
}
.coin-section-header {
  text-align: center;
  margin: 0 auto;
  display: block;
}
.coin-section-header h1 {
  font-family: "Knockout 50 A", "Knockout 50 B";
  font-style: normal;
  font-weight: 400;
  font-size: 45px;
  color: #00A6CC;
  letter-spacing: 4px;
  padding-bottom: 50px;
}
.subheader {
  font-style: "Gotham A", "Gotham B", Arial, sans-serif;
  font-weight: 500;
  color: #AAAAAA;
  font-size: 20px;
  width: 65%;
  text-align: center;
  margin: 0 auto;
  display: block;
  line-height: 2rem;
  padding-bottom: 200px;
}
.other-companies-header {
  font-family: "Knockout 50 A", "Knockout 50 B";
  font-style: normal;
  font-weight: 400;
  color: #00A6CC;
  letter-spacing: 4px;
  padding-bottom: 50px;
  font-size: 35px;
  padding-top: 80px;
  text-align: center;
}
/* Weird alignment because the design has the 3 sections not perfectly centered */
.profit-container {
  margin-left: 5%;
}
.profit-stack-of-coins-container {
  height: 251px;
  margin: auto;
}
.profit-stack-of-coins-container img {
  max-width: 325.5px;
  min-width: 250px;
  width: 100%;
  margin: auto;
}
.mortgage-process-header {
  font-family: "Knockout 50 A", "Knockout 50 B";
  font-style: normal;
  font-weight: 400;
  color: #00A6CC;
  letter-spacing: 4px;
  padding-bottom: 50px;
  font-size: 35px;
  padding-top: 80px;
  text-align: center;
}
.other-companies-container { 
  display: flex; 
  flex-direction: column;
  justify-content: center;
  width: 34%;
}
.mortgage-process-container {
  display: flex; 
  flex-direction: column;
  justify-content: center;
  width: 40%;
}
.other-companies-subheading {
  font-style: "Gotham A", "Gotham B", Arial, sans-serif;
  font-weight: 500;
  color: #AAAAAA;
  font-size: 20px;
  text-align: center;
  padding-bottom: 90px;
}
.movement-mortgage-subheading {
  font-style: "Gotham A", "Gotham B", Arial, sans-serif;
  font-weight: 500;
  color: #AAAAAA;
  font-size: 20px;
  text-align: center;
  padding-bottom: 90px;
}
.top-yellow-arrow {
  background-image: url("https://movement-assets.imgix.net/media/movementfoundation/coins-yellow-arrow-top.svg");
  background-repeat: no-repeat;
  background-size: cover;
  margin: auto;
  width: 37px;
  height: 107px;
  margin-bottom: 20px;
}
.line-container {
  background-image: url("https://movement-assets.imgix.net/media/movementfoundation/vertical-line.svg");
  background-repeat: no-repeat;
  background-size: cover;
  height: 1180px;
  width: 1px;
  position: relative;
  margin: 0 auto;
  z-index: -1;
  display: flex;
  justify-content: center;
}
/* Had to temporarily make margin 25 percent because it's not centered, sorry it's weird and I don't like it - Peter 2020 */
.speech-bubble-profit-container {
  height: 77.85px;
  margin: 100px auto 0 25%;
  width: 100%;
}
.speech-bubble-profit-container {
  position: sticky;
  top: 440px;
}
.speech-bubble-profit-container img {
  max-width: 174.07px;
  min-width: 124.07px;
  width: 51%;
}
.coin-sections-animations {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 75%;
  margin: 0 auto;
  padding-top: 40px;
}

/*Inner Animated bits*/
.top-coins {
  height: 1500px;
}
/* Left Side */
.coin-cointainer-1 {
  width: 40%;
  height: 100px;
  margin-left: 35%;
}
.coin-cointainer-2 {
  width: 40%;
  height: 100px;
  margin-left: 30%;
}
.coin-cointainer-3 {
  width: 40%;
  height: 100px;
  margin-left: 60%;
}
.coin-cointainer-4 {
  width: 40%;
  height: 100px;
  margin-top: 30px;
  margin-left: 10%;
}
.coin-cointainer-5 {
  width: 40%;
  height: 100px;
  margin-left: 10%;
}
.speech-bubble-red-investors {
  height: 62.69px;
  margin-top: -100px;
}
.speech-bubble-red-investors img {
  max-width: 213.02px;
  min-width: 153.02px;
  width: 54%;
}
.investors-text-container {
  font-style: "Gotham A", "Gotham B", Arial, sans-serif;
  font-weight: 500;
  color: #AAAAAA;
  font-size: 20px;
  text-align: left;
  width: 40%;
  margin-top: -110px;
  line-height: 2rem;
  max-width: 160px;
}
.hand-container {
  height: 136px;
  margin-left: 60%;
}
.wall-street-image {
  height: 203.56px;
  margin-top: 80px;
}
.wall-street-image img {
  max-width: 257.09px;
  min-width: 200px;
  width: 60%;
}
.bottom-left-yellow-arrow {
  height: 214.18px;
  margin-top: -200px;
  margin-left: 75%;
}
.bottom-left-yellow-arrow img {
  max-width: 163.24px;
  min-width: 100.24px;
  width: 125%;
}
.speech-bubble-red-leftover {
  height: 64px;
  margin-top: -170px;
  margin-left: 71%;
}
.speech-bubble-red-leftover img {
  max-width: 195.42px;
  min-width: 155.42px;
  width: 200%;
}
.leftover-text-container {
  font-style: "Gotham A", "Gotham B", Arial, sans-serif;
  font-weight: 500;
  color: #AAAAAA;
  font-size: 20px;
  text-align: left;
  margin-left: 71%;
  margin-top: 30px;
  width: 45%;
  line-height: 2rem;
  max-width: 195px;
}
.tiny-stack-of-coins-left {
  height: 65px;
}
.tiny-stack-of-coins-left img {
  max-width: 208.67px;
  min-width: 168.67px;
  width: 45%;
}

/*Right Side */
.upsidedown-hand-container {
  /* width: 200px; */
  height: 250px;
  margin-left: 46%;
}
.speech-bubble-yellow-right {
  height: 93.36px;
  margin-top: -50px;
}
.speech-bubble-yellow-right img {
  max-width: 195.79px;
  margin-top: -50px;
  width: 44%;
}
.right-coin-cointainer-1 {
  width: 35%;
  height: 100px;
  margin-top: -300px;
  margin-left: 63%;
}
.right-coin-cointainer-2 {
  width: 35%;
  height: 100px;
  margin-left: 70%;
}
.right-coin-cointainer-3 {
  width: 35%;
  height: 100px;
  margin-left: 64%;
}
.right-coin-cointainer-4 {
  width: 35%;
  height: 68px;
  margin-left: 70%;
}
.right-coin-cointainer-5 {
  width: 35%;
  height: 100px;
  margin-left: 50%;
}
.right-coin-cointainer-6 {
  width: 35%;
  height: 68px;
  margin-left: 72%;
  margin-top: -30px;
}
.right-coin-cointainer-7 {
  width: 35%;
  height: 100px;
  margin-left: 50%;
}
.more-to-give-text-container {
  font-style: "Gotham A", "Gotham B", Arial, sans-serif;
  font-weight: 500;
  color: #AAAAAA;
  font-size: 20px;
  text-align: left;
  margin-top: -20px;
  /* margin-left: 50px; */
  width: 60%;
  line-height: 2rem;
}
.big-bucket-coin-stack {
  margin-left: 10%;
}
.big-bucket-coin-stack img {
  max-width: 412.52px;
  min-width: 312.52px;
  width: 100%;
}
@media only screen and (max-width: 1440px) {
  .mortgage-process-header {
    font-size: 30px;

  }
  .other-companies-header {
    font-size: 30px;
  }
  .movement-mortgage-subheading {
    font-size: 18px;
  }
  .other-companies-subheading {
    font-size: 18px;
  }
  .more-to-give-text-container {
    font-size: 18px;
  }
  .leftover-text-container {
    font-size: 18px;
  }
  .investors-text-container {
    font-size: 18px;
  }
 }
@media only screen and (max-width: 1368px) {
 .upsidedown-hand-container {
   height: 200px;
 }
 .line-container {
   height: 1120px;
 }
 .coin-cointainer-4 {
  height: 90px;
  margin-top: 0;
 }
 .wall-street-image {
   margin-top: 30px;
 }
}
/*I don't like this but it's a bandaid for the dang unequal widths for the 3 columns ( looking at you middle profit container *glare* ) - Peter 2020 */
@media only screen and (max-width: 1300px) {
  .mortgage-process-container {
    width: 50%;
  }
  .leftover-text-container{
    width: 70%;
  }
  .right-coin-cointainer-1 {
    height: 90px;
  }
  .right-coin-cointainer-2 {
    height: 90px;
  }
  .right-coin-cointainer-3 {
    height: 90px;
  }
  .right-coin-cointainer-4 {
    height: 90px;
  }
  .right-coin-cointainer-5 {
    height: 90px;
  }
  .right-coin-cointainer-6 {
    height: 90px;
  }
  .right-coin-cointainer-7 {
    height: 90px;
  }
 
  /*Idk anymoreeee*/
  .other-companies-container {
    width: 45%;
  }
}
@media only screen and (max-width: 1250px) {
  .profit-container {
    margin-left: 11%;
  }
  .leftover-text-container{
    width: 100%;
  }
  .investors-text-container {
    width: 80%;
  }
  .coin-cointainer-3 {
    margin-left: 75%;
  }
  .hand-container {
    margin-left: 80%;
  }
  .bottom-left-yellow-arrow {
    margin-left: 100%;
  }
  .upsidedown-hand-container {
    height: 200px;
  }
  .coin-cointainer-4 {
    height: 100px;
    margin-top: 10px;
   }
  .wall-street-image {
    margin-top: 80px;
  }
  .line-container {
    height: 1200px;
  }
}
/* Tablet */
@media only screen and (max-width: 1024px) {
  .howitworks-component {
    height: 4000px;
    /* Weird fix to adjust the spacing between sections */
    margin-top: -190px;
  }
  .coin-cointainer-3 {
    margin-left: 60%;
  }
  .coin-section-top {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    /* justify-content: space-between; */
    /* width: 75%; */
  }
  .profit-stack-of-coins-container {
    order: 1;
  }
  .other-companies-container { 
    order: 2;
    width: 65%;
    margin: 0 auto;
  }
  .mortgage-process-container {
    order: 3;
    width: 65%;
    margin: 0 auto;
  }
  .profit-container {
    margin-left: 0;
    margin: 0 auto;
  }
  .line-container {
    display: none;
  }
  /*Text containers */
  .more-to-give-text-container {
    max-width: 300px;
    font-size: 16px;
  }
  .leftover-text-container {
    max-width: 165px;
    font-size: 16px;
  }
  .investors-text-container {
    max-width: 160px;
    font-size: 16px;
  }
  .upsidedown-hand-container {
    height: 250px;
  }
  .hand-container {
    margin-left: 60%;
  }
  .bottom-left-yellow-arrow {
    margin-left: 75%;
  }
  .subheader {
    padding-bottom: 100px;
  }
}
@media only screen and (max-width: 868px) {
  .speech-bubble-yellow-right img {
    min-width: 162px;
  }
}
@media only screen and (max-width: 756px) {
  .upsidedown-hand-container {
    height: 225px;
  }
}
@media only screen and (max-width: 640px) {
  .howitworks-component {
    height: 3760px;
  }
  .coin-section-left {
    margin-left: -62px;
  }
  .coin-section-right {
    margin-left: -50px;
  }
  .right-coin-cointainer-1 {
    height: 70px;
  }
  .right-coin-cointainer-2 {
    height: 70px;
  }
  .right-coin-cointainer-3 {
    height: 70px;
  }
  .right-coin-cointainer-4 {
    height: 70px;
  }
  .right-coin-cointainer-5 {
    height: 70px;
  }
  .right-coin-cointainer-6 {
    height: 70px;
  }
  .right-coin-cointainer-7 {
    height: 70px;
  }
 
  /*Text containers*/
  .more-to-give-text-container {
    font-size: 15px;
  }
  .leftover-text-container {
    font-size: 15px;
  }
  .investors-text-container {
    font-size: 15px;
  }
  .speech-bubble-profit-container {
    width: unset;
    text-align: center;
    margin: 75px 0 0 0;
  }
  .speech-bubble-yellow-right img {
    width: 55%;
    min-width: unset;
  }
  .hand-container {
    margin-left: 50%;
  }
}
/*Sorry this happened because i put the dang text container and hand containers + coins in the same parent without flex ughhh */
@media only screen and (max-width: 567px) {
  .upsidedown-hand-container {
    height: 200px;
  }
  .howitworks-component {
    height: 3800px;
  }
}
@media only screen and (max-width: 510px) { 
  .howitworks-component {
    height: 3900px;
  }
}
@media only screen and (max-width: 480px) {
  .howitworks-component {
    height: 4000px;
  }
}
@media only screen and (max-width: 430px) {
  .howitworks-component {
    height: 4040px;
  }
}
@media only screen and (max-width: 410px) {
  .hand-container {
    margin-left: 70%;
  }
  .hand-container img {
    min-width: 140px;
    width: 150%;
  }
  .coin-cointainer-3 {
    margin-left: 70%;
  }
  .big-bucket-coin-stack {
    margin-left: 0;
  }
  .big-bucket-coin-stack img {
    min-width: 288.52px;
  }
  .leftover-text-container {
    max-width: 125px;
  }
}
@media only screen and (max-width: 380px) {
  .hand-container {
    margin-left: 65%;
  }
  .howitworks-component {
    height: 4160px;
  }
  .speech-bubble-red-leftover {
    margin-left: 66%;
  }
}
@media only screen and (min-width: 2010px) {
  .big-bucket-coin-stack img {
      max-width: 490px;
  }
  .big-bucket-coin-stack  {
    margin-left: 12%;
  }
  .upsidedown-hand-container {
    height: 305px;
  }
}
@media only screen and (min-width: 2400px) {
  .big-bucket-coin-stack img {
    max-width: 490px;
}
  .big-bucket-coin-stack  {
    margin-left: 25%;
  }
  .more-to-give-text-container {
    max-width: 300px;
  }
  .upsidedown-hand-container {
    height: 250px;
  }
}

/* End How It Works CSS */