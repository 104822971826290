/*
@import url(Media-1920.css);
@import url(Media-1440.css);
@import url(Media-768.css);
@import url(Media-414.css);
@import url(Media-320.css);
*/

/*320*/
@media only screen and (max-width: 320px) {
    footer {
        background-color: #013D57;
        padding: 3rem 2.2rem 0rem 2.2rem;
    }
    footer div {
     
    }
    .footer-icon {
        width: 51px;
        height: 51px;
        object-fit: cover;
    }
    footer span {
         font-size: 16px;
        letter-spacing: 1.5px;
        padding-top: 14px;
    }
    footer span img{
         padding-left: 10px;
         height:11px;
     }
    .dotted-border {
        border-bottom: 2px dotted #deb325;
    }
    .career {
        padding: 2.75rem 0;
    }
    .press {
        padding: 2.75rem 0 1.6rem 0;
     }
     .footer-txt {
        color: #fff;
        font-family: "Gotham A", "Gotham B";
        font-style: normal;
        font-weight: 500;
        font-size: 0.73rem !important;
        line-height: 1.75rem;
        max-width: 200px;
        margin: auto;
        align-items: center;
        /*letter-spacing: .1rem;
        padding: 2rem 1.6rem 1rem 1.6rem;*/
     }
     
     .footer-copy {
         padding: 0.75rem 0 3.6rem 0;
         margin: -1rem -2rem;
         line-height: 2rem;
     }
     .footer-copy h6 {
        color: #fff;
        font-family: "Gotham A", "Gotham B";
        font-style: normal;
        font-weight: 500;
        font-size: 12px !important;
        letter-spacing: 0.2px;
     }
     br.responsive, br.footer-brk {
         display: none;
     }
     .footer-right-border-withCopyright {
         display: none !important;
     }
 }
/*320*/
/*414*/
@media only screen and (min-width: 321px) and (max-width: 640px) {
    footer {
        background-color: #013D57;
        padding: 3.3rem 2.5rem 1rem 2.5rem;
    }
    .footer-icon {
        width: 58px;
        height: 58px;
        object-fit: cover;
    }
    footer div {
     flex: 100%;
     max-width: 300px;
     align-items: center;
     margin: auto;
    }
    footer span {
        font-size: 18px;
        letter-spacing: 1.5px;
        padding-top: 20px;
    }
    footer span img {
         padding-left: 10px;
         height: 12px;
    }
    .dotted-border {
        border-bottom: 2px dotted #deb325;
    }
    .career {
        padding: 2.8rem 0;
    }
    .press {
         padding: 2.8rem 0;
     }
     .footer-txt {
        color: #fff;
        font-family: "Gotham A", "Gotham B";
        font-style: normal;
        font-weight: 500;
        font-size: 0.73rem !important;
        /*padding: 2rem 7.5rem 1rem 7.5rem;*/
        line-height: 1.75rem;
        letter-spacing: 0.1rem;
        max-width: 315px;
        margin: auto;
        align-items: center;
    }
     .footer-copy {
         padding-top: 0rem;
     }
     .footer-copy h6 {
        color: #fff;
        font-family: "Gotham A", "Gotham B";
        font-style: normal;
        font-weight: 500;
        font-size: 0.73rem !important;
        letter-spacing: .1rem;
        line-height: 1rem;
        margin-top:0.75rem;
     }
     br.responsive, br.footer-brk {
        display: none;
    }
    .footer-right-border-withCopyright {
        display: none !important;
    }
}
/*414*/
/*768*/
@media only screen and (min-width: 641px) and (max-width: 1024px) {
    footer {
        background-color: #013D57;
        padding: 5.5rem 5rem 3.5rem 5rem;
    }
    .footer-icon {
        width: 60px;
        height: 60px;
        object-fit: cover;
    }
    footer div {
     flex: 100%;
     padding: 0!important;
    }
    footer span {
        font-size: 20px;
        letter-spacing: 1.5px;
        padding-top: 20px;
    }
    footer span img{
         padding: 1rem;
     }
    .footer-links {
        display: flex;
        margin-bottom: 5.6rem;
    }
    .footer-links img {
        display: block;
        margin: 0 auto;
    }
    .dotted-border {
        border-right: 2px dotted #deb325;
    }
    .logo-img {
        object-fit: cover;
        width: 45px;
        height: 45px;
    }
    .footer-txt {
        color: #fff;
        font-family: "Gotham A", "Gotham B";
        font-style: normal;
        font-weight: 500;
        font-size: 15px !important;
        padding: 2rem 2.2rem 1rem 2.2rem!important;
        line-height: 2rem;
        letter-spacing: 0.08rem;
        max-width: 70%;
        margin: 0 auto;
    }
    .footer-copy {
        padding-top: 1rem;
    }
    .footer-copy h6 {
        color: #fff;
        font-family: "Gotham A", "Gotham B";
        font-style: normal;
        font-weight: 500;
        font-size: 15px !important;
        letter-spacing: 0.1rem;
        margin-top:1rem;
        line-height: 1rem;
    }
    br.footer-brk {
        /*display: none;*/
    }
    .footer-right-border-withCopyright {
        display: none !important;
    }
    
}
/*768*/
/*1440*/
@media only screen and (min-width: 1025px) and (max-width: 1440px) {
    footer {
        background-color: #013D57;

        padding: 7.8rem 10rem 5.4rem 10rem;
    }
    .footer-icon {
        width: 60px;
        height: 60px;
        object-fit: cover;
    }
    footer div {
     flex: 100%;
     padding: 0!important;
    }
    footer span {
        font-size: 30px;
        letter-spacing: .3rem;
        padding-top: 20px;
    }
    footer span img{
         padding: 1rem;
     }
    .footer-links {
        display: flex;
        margin-bottom: 7.8rem;
    }
    .footer-links img {
        display: block;
        margin: 0 auto;
    }
    .dotted-border {
        border-right: 2px dotted #deb325;
    }
    .logo-img {
        object-fit: cover;
        width: 45px;
        height: 45px;
    }
    .footer-txt {
        color: #fff;
        font-family: "Gotham A", "Gotham B";
        font-style: normal;
        font-weight: 500;
        font-size: 15px !important;
        padding: 2rem 7rem 1rem 7rem!important;
        line-height: 2rem;
        letter-spacing: 0rem;
    }
    .footer-copy {
        padding-top: 1rem;
    }
    .footer-copy h6 {
        color: #fff;
        font-family: "Gotham A", "Gotham B";
        font-style: normal;
        font-weight: 500;
        font-size: 15px !important;
        display: inline-block;
        padding: 0px 2rem;
        margin-top: 1rem;
    }
    .footer-copy .footer-right-border {
        border-right: 2px solid #fff;
    }
    br.footer-brk {
        display: none;
    }
    .footer-right-border-withoutCopyright {
        display: none !important;
    }
}
/*1440*/
/*1920*/
@media only screen and (min-width: 1441px) and (max-width: 1920px) {
    footer {
        background-color: #013D57;
        padding: 10.5rem 11rem 9.5rem 11rem;
    }
    .footer-icon {
        width: 60px;
        height: 60px;
        object-fit: cover;
        
    }
    footer div {
     flex: 100%;
     padding: 0!important;
    }
    footer span {
        font-size: 30px;
        letter-spacing: 0.2rem;
        padding-top: 56px;
    }
    footer span img{
         padding: 1rem;
     }
    .footer-links {
        display: flex;
        margin-bottom: 9.8rem;
    }
    .footer-links img {
        display: block;
        margin: 0 auto;
    }
    .dotted-border {
        border-right: 2px dotted #deb325;
    }
    .logo-img {
        object-fit: cover;
        width: 60px;
        height: 60px;
    }
    .footer-txt {
        color: #fff;
        font-family: "Gotham A", "Gotham B";
        font-style: normal;
        font-weight: 500;
        font-size: 15px !important;
        padding: 2rem 21rem 1rem 21rem!important;
        line-height: 2rem;
        letter-spacing: 0rem;
    }
    .footer-copy {
        padding-top: 1rem;
    }
    .footer-copy h6 {
        color: #fff;
        font-family: "Gotham A", "Gotham B";
        font-style: normal;
        font-weight: 500;
        font-size: 15px !important;
        letter-spacing: .1rem;
        display: inline-block;
        padding: 0 3rem;
    }
    .footer-copy .footer-right-border {
        border-right: 2px solid #fff;
    }
    br.footer-brk {
        display: block;
    }
    .footer-right-border-withoutCopyright {
        display: none !important;
    }
}
@media only screen and (min-width:1920px) {
    footer {
        background-color: #013D57;
        padding: 10.5rem 11rem 9.5rem 11rem;
    }
    .footer-icon {
        width: 60px;
        height: 60px;
        object-fit: cover;
        
    }
    footer div {
     flex: 100%;
     padding: 0!important;
    }
    footer span {
        font-size: 30px;
        letter-spacing: 0.2rem;
        padding-top: 56px;
    }
    footer span img{
         padding: 1rem;
     }
    .footer-links {
        display: flex;
        margin-bottom: 9.8rem;
    }
    .footer-links img {
        display: block;
        margin: 0 auto;
    }
    .dotted-border {
        border-right: 2px dotted #deb325;
    }
    .logo-img {
        object-fit: cover;
        width: 60px;
        height: 60px;
    }
    .footer-txt {
        color: #fff;
        font-family: "Gotham A", "Gotham B";
        font-style: normal;
        font-weight: 500;
        font-size: 15px !important;
        padding: 2rem 21rem 1rem 21rem!important;
        line-height: 2rem;
        letter-spacing: 0rem;
    }
    .footer-copy {
        padding-top: 1rem;
    }
    .footer-copy h6 {
        color: #fff;
        font-family: "Gotham A", "Gotham B";
        font-style: normal;
        font-weight: 500;
        font-size: 15px !important;
        letter-spacing: .1rem;
        display: inline-block;
        padding: 0 3rem;
    }
    .footer-copy .footer-right-border {
        border-right: 2px solid #fff;
    }
    br.footer-brk {
        display: block;
    }
    .footer-right-border-withoutCopyright {
        display: none !important;
    }
}
/*1920*/

footer {
    text-align: center;
}

footer div {
    padding: 1rem 0 2.5rem 0;
}

footer span {
    color: #fff;
    text-transform: uppercase;
    display: block;
    font-family: "Knockout 50 A", "Knockout 50 B";
    font-style: normal;
    font-weight: 400;
}

a {
  text-decoration:none;
}